import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
//import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom"; **
import { Route, BrowserRouter, Switch } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "./style/LineIcons/WebFont/font-css/LineIcons.css";
// Custom css
import "./style/style.css";

// import components
const ErrorBoundary = lazy(() => import("./components/common/errorBoundary"));
const Layout = lazy(() => import("./components/common/layout"));
const AuthSignIN = lazy(() => import("./components/auth/sign_in"));
const Campaigns = lazy(() => import("./components/campaigns/index"));
const Trackers = lazy(() => import("./components/trackers/index"));
const Report = lazy(() => import("./components/report/index"));
const Editor = lazy(() => import("./components/editor/index"));
const Analytics = lazy(() => import("./components/campaigns/analytics"));
const ChangePassword = lazy(() => import("./components/changepassword/index"));
const ForgotPassword = lazy(() => import("./components/auth/forgot_password"));
const ResetPassword = lazy(() => import("./components/auth/reset_password"));

const NoMatch = lazy(() => import("./components/common/no_match"));

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          <Route exact path="/status">
            <span>I Am Alive</span>
          </Route>
          <Route exact path="/login" component={AuthSignIN} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/public/:action" component={ResetPassword} />
          <Route exact path="/changepassword" component={ChangePassword} />

          <Layout>
            <Route
              component={({ match }) => (
                <div>
                  <Route exact path="/" component={Campaigns} />
                  <Route exact path="/campaigns" component={Campaigns} />
                  <Route exact path="/analytics" component={Analytics} />
                  <Route exact path="/trackers" component={Trackers} />
                  <Route path="/shared/:id" component={Report} />
                  <Route exact path="/report/:id" component={Report} />
                  <Route exact path="/editor" component={Editor} />
                  <Route exact path="/editor/:id" component={Editor} />
                </div>
              )}
            />
          </Layout>
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
